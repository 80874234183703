// import angular from 'angular';
import loader from '../utils/loader';

const bulk = require('bulk-require');

const dependencies = [];

const servicesModule = angular.module('ada.services', dependencies);
const services = bulk(__dirname, ['./**/!(*index|*.spec).js']);

loader(servicesModule, 'factory', services);

export default servicesModule;
