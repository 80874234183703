// @ngInject
function SidemenuService($rootScope, $http, $state) {
    var sidemenu = {};
    sidemenu.observerCallbacks = [];
    sidemenu.trails = [];

    function getCurrentState() {
        return $state.current.name;
    }

    // register an observer
    function registerObserver(callback){
        sidemenu.observerCallbacks.push(callback);
    };

    function notify(){
        angular.forEach(sidemenu.observerCallbacks, function(callback){
            callback();
        });
    };

    function push(trail){
        sidemenu.trails.push(trail);
        notifyObservers();
    };

    function pop() {
        sidemenu.trails.pop();
        notifyObservers();
    }

    function clear() {
        sidemenu = [];
        notifyObservers();
    }

    function set(trails) {
        sidemenu.trails = trails;
        notifyObservers();
    }

    /*
        @description: iterate over all registered callbacks and call then clear
    */
    function notifyObservers() {
        for (var cb of sidemenu.observerCallbacks) {
            cb();
        }

        sidemenu.observerCallbacks = [];
    }

    return {
        current: getCurrentState,

        registerObserverCallback: registerObserver,
        notifyObservers: notify,

        push: push,
        pop: pop,
        clear: clear,
        set: set
    };
}

export default {
    name: 'SidemenuService',
    fn: SidemenuService
}
