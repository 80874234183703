// @ngInject
function NavigationService($q, $state, $rootScope, AuthService) {
    // var service = this;

    // service.show = true;
    // service.trails = [];
    // service.ignore = false;
    //
    // // constants
    // service.BREADCRUMB_UPDATE = 'BREADCRUMB_UPDATE';
    //
    // service.push = function (crumb) {
    //     service.trails.push(crumb);
    //     $rootScope.$broadcast(service.BREADCRUMB_UPDATE);
    // }
    //
    // // check the state and build the trail
    // $rootScope.$on('$stateChangeSuccess', function(){
    //     service.build();
    //     $rootScope.$broadcast(service.BREADCRUMB_UPDATE);
    // });
    //
    // service.build = function() {
    //     if ($state.current.breadcrumb && $state.current.breadcrumb.hide) {
    //         service.show = false;
    //         return
    //     }
    //
    //     service.show = true;
    //     service.trails = [];
    //     for (var state in $state.$current.includes) {
    //         if (state.length == 0) { continue; }
    //         var cstate = $state.get(state);
    //         if (!cstate.breadcrumb || cstate.breadcrumb.ignore) { continue; }
    //
    //         var pstate = {sref:cstate.name}
    //         if (cstate.breadcrumb.top) { service.trails = []; }
    //         pstate.label = cstate.breadcrumb.label || "";
    //
    //         service.trails.push(pstate);
    //     }
    // }

    function init() {
        return $q((resolve) =>{

            resolve();
        });
    }

    function getTree() {

    }

    function push() {

    }

    function pop() {

    }

    /*
        @description: parses a path string from ui-router and just returns the substring with the last path omitted
        @params: path - string
    */
    function getParentState(path) {
        return path.substring(0, path.lastIndexOf('.'))
    }

    return {
        init: init,
        getTree: getTree,
        push: push,
        pop: pop,

        getParentState: getParentState
    }
}

export default {
    name: 'NavigationService',
    fn: NavigationService
}
