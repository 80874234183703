// @ngInject
function ResourcesCtrl($scope, $http, API, Session, $timeout, Auth) {
    var captions = Session.getJSON('captions');
    $scope.tagResourcesTitle = captions.resources_title;
    $scope.assets = {};
    $scope.url = API.url();
    var user = Session.getJSON("user");

    API.get("/assets")
        .then((response) => {
            $scope.assets = response.data;
        }, function (error) {
            $scope.toastMessage = error.data.message;
            $scope.toastClass = "alert-danger";
            $timeout(() => {
                $scope.loading = false;
            }, 100);
        });

    /*--------------------------------------------------------------------------
    Definitions
    --------------------------------------------------------------------------*/
    $scope.downloadLink = downloadLink;
    $scope.closeToast = closeToast;
    $scope.getAsset = getAsset;    
    $scope.logUrlAccess = logUrlAccess;
    

    /*--------------------------------------------------------------------------
    Implementations
    --------------------------------------------------------------------------*/

    function downloadLink($event, visitId, asset) {
        // ng-href=`http://localhost:32972/api/v1/visits/${visitId}/letters/?attachmentName=${asset.file_name}`
        API.get(`/visits/${visitId}/letters/?attachmentName=${asset.file_name}`);
    }

    function closeToast() {
        $scope.toastMessage = null;
        $scope.toastClass = null;
    }    

    function getAsset(assetId) {
        Auth.getResource(`/assets/${assetId}/file?`, user.user_id)
            .then(null, () => {
                console.log("Failure");
            });
    }

    function logUrlAccess(assetId, url){
        Auth.logUrl(`/assets/${assetId}/url?`, user.user_id, url)
            .then(null,() =>{
                console.log("Failure");
            } );
    }
    
}

export default {
    fn: ResourcesCtrl,
    name: 'ResourcesCtrl'
}
