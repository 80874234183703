// @ngInject
function LogDecorator(format) {
    var logDecorator = {};
    var dateFormat = format;

    // @ngInject
    function decorator($delegate, $filter) {
        var context = '';
        var debug = $delegate.debug;
        var info = $delegate.info;
        var error = $delegate.error;
        var log = $delegate.log;
        var warn = $delegate.warn;

        // creates a new log instance with contextual message
        $delegate.setContext = function(ctx) {
            return {
                debug: wrapMessage(debug, ctx),
                info: wrapMessage(info, ctx),
                error: wrapMessage(error, ctx),
                log: wrapMessage(log, ctx),
                warn: wrapMessage(warn, ctx)
            }
        }

        function wrapMessage(caller, context) {
            return function() {
                var args = [].slice.call(arguments);
                var now = new Date();
                var dateFilter = $filter('date');

                // Prepend context and timestamp
                var message = `[${dateFilter(now, dateFormat)}]`;
                if (context) { message += `[${context}]`; }
                message += ` - ${args[0]}`;
                args[0] = message;

                // Call the original with the output prepended with formatted timestamp
                caller.apply(null, args);
            }
        }

        return $delegate;
    }

    logDecorator.decorator = decorator;

    return logDecorator;
}

export default LogDecorator;
