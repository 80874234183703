const Regex = {
    email: /^$|^[^\s@]+@[^\s@]+\.[^\s@]{2,200}$/,
    // password: /[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/,
    // postal_code: /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/i,
    // special_characters: /^[`~,<>;':\"\/\[\]\|{}()=+-]*/
};

export default {
    name: 'Regex',
    fn: Regex
}
