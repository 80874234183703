// @ngInject
function VisitFormCtrl($scope, Inspection, $state, $http, Lightbox, API, moment, Auth, $uibModal, Upload, $timeout, Session) {

    // ---Scope variables 

    $scope.rec = {};
    $scope.loading = true;
    $scope.comments = [];
    $scope.fields = {};
    $scope.commentFiles = [];
    $scope.addedFile = {};
    var visitId = $state.params.id;
    var photoModal = null;
    var deletePhotoModal = null;
    var captions = Session.getJSON('captions');
    $scope.recommendation_comment = captions['recommendation_comment']
    $scope.rci_recommendation_photos = captions['rci_recommendation_photos']
    $scope.ip_recommendation_photos = captions['ip_recommendation_photos']
    $scope.update_recommendations = captions['update_recommendations']
    $scope.update_recommendations = captions['update_recommendations']
    $scope.visits_communications = captions['visits_communications']    
    $scope.visits_attachments = captions['visits_attachments']    
    $scope.isUploading = false;



    /*--------------------------------------------------------------------------
    Definitions
    --------------------------------------------------------------------------*/
    $scope.ipPhotos = [];
    $scope.rciPhotos = [];
    $scope.addedPhoto = {};
    $scope.parseDate = parseDate;
    $scope.AddComment = AddComment;
    $scope.removeAttachment = removeAttachment;
    $scope.closeToast = closeToast;
    $scope.showCommunications = showCommunications;
    $scope.addFile = addFile;
    $scope.downloadAttachment = downloadAttachment;

    API.get("/visits/" + visitId)
        .then((response) => {
            var visit = response.data.visit;
            console.log(response);
            $scope.visit = visit;
            for (var c = 0; c < visit.comments.length; c++){
                $scope.comments.push(visit.comments[c]);
            }
            $scope.fields = response.data.columns;

            $timeout(() => {
                $scope.loading = false;
            }, 100);
        }
        , function (error) {
            $scope.toastMessage = error.data.message;
            $scope.toastClass = "alert-danger";
            $timeout(() => {
                $scope.loading = false;
            }, 100);
        });

    /*--------------------------------------------------------------------------
    Implementations
    --------------------------------------------------------------------------*/

    function addFile() {
        if ($scope.addedFile != null) {
            $scope.commentFiles.push($scope.addedFile);
            $scope.addedFile = {};
        }
    }

    function downloadAttachment(attachment){
        Auth.getResource("/comments/" + attachment.CommentId + "/attachment/" + attachment.Id + "?")
            .then(null, () => {
                console.log("Failure");
            });
    }

    function removeAttachment(index) {
        if ($scope.commentFiles[index] != null) {
            $scope.commentFiles.splice(index, 1);
        }
    }

    function showCommunications() {
        $state.go("app.communications", { 'id': visitId });
    }

    function AddComment(comment) {
        var addedComment = comment;
        $scope.commentInput = null;
        

        var uploadData = { 'comment': addedComment };
        for (var p = 0; p < $scope.commentFiles.length; p++) {
            uploadData[$scope.commentFiles[p].name] = $scope.commentFiles[p];
        }
        Upload.upload({
            url: API.url() + '/comments/' + visitId + "/add",
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + Auth.getToken()
            },
            data: uploadData
        })
            .then(
            function (response) {
                console.log(response);
                var attachments = [];
                for (var c = 0; c < $scope.commentFiles.length; c++){
                    attachments.push({
                        CommentId: response.data.id,
                        Id: response.data.attachments[c].id,
                        AttachmentName: response.data.attachments[c].attachment_name
                        });
                }

                var inspectionComment = {
                    Comment: response.data.comment,
                    Date: response.data.date,
                    Author: response.data.author,
                    Attachments: attachments,
                    User: true
                }

                $scope.comments.push(inspectionComment);
                $scope.commentFiles = [];
            },
            function (error) {
                $scope.toastMessage = error.data.message;
                $scope.toastClass = "alert-danger";
            }
            );
            API.post("/email/inspection/comment/" + visitId)
                .then("",function(error){
                    $scope.toastMessage = error.data.message;
                    $scope.toastClass = "alert-danger";
                });            
    }

    function parseDate(dateString) {
        // POLICY: prevent displaying 0
        if (!dateString || dateString == '0' || dateString == '') { return '-'; }

        return moment(dateString, "YYYYMMDD").format("YYYY-MMM-DD");
    }

    function closeToast() {
        $scope.toastMessage = null;
        $scope.toastClass = null;
    }

}

export default {
    name: 'VisitFormCtrl',
    fn: VisitFormCtrl
};
