// @ngInject
function ForgotPasswordCtrl($scope, $rootScope, $http, Session, $timeout, $state, API, Auth, Caption, $log) {
    var logger = $log.setContext("ForgotPasswordCtrl");
    
    $rootScope.rootClass = '';
    $scope.resetInfo = {
        'email' : ""
    };

    $scope.errors = null;

    /*--------------------------------------------------------------------------
    Definitions
    --------------------------------------------------------------------------*/
    $scope.resendEmail = resendEmail;
    /*--------------------------------------------------------------------------
    Implementations
    --------------------------------------------------------------------------*/

    function resendEmail (){
        API.post("/reset", $scope.resetInfo)
            .finally((response) => {
                $scope.errors = "Recovery email sent"
                $timeout(() => {
                    $state.go("login");
                }, 1500);
            });
    }
}
export default {
    name: 'ForgotPasswordCtrl',
    fn: ForgotPasswordCtrl
};
