// @ngInject
function CaptionService(API, $q, Session, $log) {
    var _captions = null;


    function init() {
        $log.debug("Caption.init()");

        return $q((resolve, reject) => {
            _captions = Session.getJSON('captions') || {};

            if (_captions) {
                resolve(_captions);
            } else {
                getAll().then(resolve, reject);
            }
        });
    }

    function getAll() {
        return $q((resolve, reject) => {
            API.get("/captions")
                .then((response) => {
                    var captionsDict = {};
                    response.data.forEach(function (caption) {
                        captionsDict[caption.key] = caption.value;
                    }, this);

                    _captions = captionsDict;
                    Session.setJSON("captions", _captions);

                    resolve(captionsDict);
                }, reject);
        })
    }

    function get() {
        return _captions;
    }


    return {
        init: init,
        getAll: getAll,

        get: get,
    }
}

export default {
    name: 'Caption',
    fn: CaptionService
};
