// @ngInject
function RCTBreadcrumbService($rootScope, $http, $state) {
    var service = this;

    service.show = true;
    service.trails = [];
    service.ignore = false;

    // constants
    service.BREADCRUMB_UPDATE = 'BREADCRUMB_UPDATE';

    service.push = (crumb) => {
        service.trails.push(crumb);
        $rootScope.$broadcast(service.BREADCRUMB_UPDATE);
    }

    // check the state and build the trail
    $rootScope.$on('$stateChangeSuccess', function(){
        service.build();
        $rootScope.$broadcast(service.BREADCRUMB_UPDATE);
    });

    service.build = () => {
        if ($state.current.breadcrumb && $state.current.breadcrumb.hide) {
            service.show = false;
            return
        }

        service.show = true;
        service.trails = [];

        for (var state in $state.$current.includes) {
            if (state.length == 0) { continue; }
            var cstate = $state.get(state);
            if (!cstate.breadcrumb || cstate.breadcrumb.ignore) { continue; }

            var pstate = {sref:cstate.name, parent: cstate.breadcrumb.parent}
            if (cstate.breadcrumb.top) { service.trails = []; }
            pstate.label = cstate.breadcrumb.label || "";

            service.trails.push(pstate);
        }
    }
}

// TODO: rename to BreadcrumbService
export default {
    name: '$breadcrumb',
    fn: RCTBreadcrumbService
}
