// @ngInject
function AppCtrl($scope, $rootScope, Session, $state) {
    $rootScope.rootClass = 'app';
    $scope.loading = true;

    var currentState = $state.current.name;
    console.log(currentState);
    if (currentState != 'confirm'
        || currentState != 'login'
        || currentState != 'adminLogin'){
        if (!Session.get("auth")) {
            $state.go("login");
        }            
    }
}

export default {
    name: 'AppCtrl',
    fn: AppCtrl
};
