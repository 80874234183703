/**
 *  LoaderDirective: <icon name="{{name}}"></icon> using elusive icons
 *  @module LoaderDirective
 */

var template = `
<div ng-if="loading" class="loader">
    <icon class="loader-icon" name="repeat"></icon>
    <div class="loader-background"></div>
</div>`;

 // @ngInject
 function LoaderDirective() {
     
     return {
         restrict: 'E',
         template: template,
         scope: {
             loading: '='
         }
     }
 }
 

export default {
    name: 'loader',
    fn: LoaderDirective
}
