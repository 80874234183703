// @ngInject
function CaptionsCtrl($scope, $http, $window, $uibModal, $location, API, Session) {


    // -- Scope Variables
    var captions = Session.getJSON("captions");
    $scope.CaptionsTitle = captions["terminology"]
    $scope.captionsHelpBody = `
        <p>Here you can alter the ` + $scope.CaptionsTitle + ` used throughout the insured portal. Simply overwrite the placeholder text to create new ` + $scope.CaptionsTitle + `</p>
    `;
    $scope.errorMessage;
    var saveQueue = [];

    /*--------------------------------------------------------------------------
    Definitions
    --------------------------------------------------------------------------*/
    $scope.saveCaptions = saveCaptions;
    $scope.transformCaptionName = transformCaptionName;
    $scope.closeToast = closeToast;
    $scope.enableMCE = enableMCE;
    $scope.isSaving = false;

    $scope.accountGroup = [];
    $scope.adminGroup = [];
    $scope.homeGroup = [];
    $scope.recommendationsGroup = [];
    $scope.visitsGroup = [];
    $scope.resourcesGroup = [];
    $scope.allCaptions = [];

    var modal = null;
    var regexCaption = /(^[a-z]|_[a-z])*/g;


    API.get('/captions')
        .then((response) => {
            console.log(response);
            for (var c = 0; c < response.data.length; c++) {
                if (response.data[c].group == "account") {
                    $scope.accountGroup.push(response.data[c])
                }
                if (response.data[c].group == "admin") {
                    $scope.adminGroup.push(response.data[c])
                }
                if (response.data[c].group == "home") {
                    $scope.homeGroup.push(response.data[c])
                }
                if (response.data[c].group == "resources") {
                    $scope.resourcesGroup.push(response.data[c])
                }
                if (response.data[c].group == "recommendations") {
                    $scope.recommendationsGroup.push(response.data[c])
                }
                if (response.data[c].group == "visits") {
                    $scope.visitsGroup.push(response.data[c])
                }
            }
            $scope.allCaptions.push({
                'group': 'account',
                'captions': $scope.accountGroup
            });
            $scope.allCaptions.push({
                'group': 'admin',
                'captions': $scope.adminGroup
            });
            $scope.allCaptions.push({
                'group': 'home',
                'captions': $scope.homeGroup
            });
            $scope.allCaptions.push({
                'group': 'resources',
                'captions': $scope.resourcesGroup
            });
            $scope.allCaptions.push({
                'group': 'recommendations',
                'captions': $scope.recommendationsGroup
            });
            $scope.allCaptions.push({
                'group': 'visits',
                'captions': $scope.visitsGroup
            });
        });

    /*--------------------------------------------------------------------------
    Implementations
    --------------------------------------------------------------------------*/

    function saveCaptions() {
        $scope.isSaving = true;
        $scope.toastMessage = null;
        $scope.toastClass = null;
        if ($scope.captionForm.$invalid) { return }
        for (var c = 0; c < $scope.allCaptions.length; c++) {
            API.put("/admin/captions", $scope.allCaptions[c].captions)
                .catch((errorResponse) => {
                    $scope.toastClass = "alert-danger";
                    $scope.toastMessage = errorResponse.data.message;
                    $scope.isSaving = false;
                });
        }
        $scope.toastClass = "alert-success";
        $scope.toastMessage = "Captions Successfully Updated";
        $scope.isSaving = false;
    }

    function enableMCE(captionName) {
        return (captionName == "textarea");
    }

    function transformCaptionName(caption) {
        return caption.replace(regexCaption, function ($1) {
            return $1.toUpperCase().replace("_", " ");
        });
    }

    function closeToast() {
        $scope.toastMessage = null;
        $scope.toastClass = null;
    }
}

export default {
    name: 'CaptionsCtrl',
    fn: CaptionsCtrl
};
