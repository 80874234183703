// @ngInject
function SettingsService(API, $q, Session, $log) {
    var _settings = null

    function init() {
        $log.debug("Setting.init()");
        return $q((resolve, reject) => {
            _settings = Session.getJSON('settings') || {}

            if (_settings) {
                resolve(_settings)
            } else {
                getAll().then(resolve, reject);
            }
        });
    }

    function getAll() {
        return $q((resolve, reject) => {
            API.get("/settings")
                .then((response) => {
                    var settingsDict = {};
                    response.data.forEach(function (setting) {
                        settingsDict[setting.key] = setting.value
                    }, this);

                    _settings = [settingsDict];
                    Session.setJSON("settings", _settings);

                    resolve(settingsDict);
                }, reject);
        })
    }
    
    function get() {
        return _settings;
    }

    return {
        init: init,
        getAll: getAll,
        get: get
    }
}

export default {
    name: "Setting",
    fn: SettingsService
};