// @ngInject
function AdminHomeCtrl($scope, $http, $window, API) {
    $scope.tagHomeTitle = "Welcome";
    $scope.tagHomeBody = `
        <p>Welcome to the insured portal administration panel. Here you can configure a variety of aspects of the insured portal. Welcome to our interactive loss control portal.</p>
    `;

}

export default {
    name: 'AdminHomeCtrl',
    fn: AdminHomeCtrl
};
