import Logger from '../../ada/ada/utils/logger';

// @ngInject
function OnRun($rootScope, API, Auth, Session, $http, $log, Caption,$location,$window) {
    var logger = Logger("OnRun", $log);
    var clientGa = null;
    var serverGa = null;

    $rootScope.$on('$stateChangeSuccess', function (event) {
        if (serverGa){
            $window.ga('send', 'pageview', $location.path());
        }

        if (clientGa){
             $window.ga('clientTracker.send', 'pageview', $location.path());
        }
    });

    API
    .init()
    .then(() => {
        return Caption.init(function(response) {
            console.log("Res: ", response);
        });
    })
    .then(() => {
        return Auth.init("bearer");
    })
    .then(() => {
        return API.get("/settings")
            .then((response) => {
                Session.setJSON("settings",response.data);
                
                for (var s = 0; s < response.data.length; s++){
                    var setting = response.data[s];
                    switch (setting.key){
                        case "tracking.client.ga":
                            clientGa = setting.value;
                            if (clientGa){
                                $window.ga('create', clientGa, 'auto', 'clientTracker');
                            } 
                            break;
                        case "tracking.server.ga":
                            serverGa = setting.value;
                            if (serverGa){
                                $window.ga('create', serverGa, 'auto');
                                
                            } 
                            break;                            
                    }                
                }
            })
        logger.debug("Finished Service Initializations")
    })
    .catch((error) => {
        $log.error("failed to init: ", error);
    });
}

export default OnRun;
