/**
 *  Logger: Initializes logger service with context if $log is decorated
 *  @module Logger
 */
function Logger(context, $log) {
    if (!$log) {
        throw "$log is not defined";
    }

    if ($log.hasOwnProperty('setContext')) {
        return $log.setContext(context);
    } else {
        return $log;
    }
}

export default Logger;
