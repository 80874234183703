// @ngInject
function GoogleAnalyticsCtrl($scope, API, Session) {
    var captions = Session.getJSON("captions");

    $scope.trackerCodes = []
    $scope.trackerCodeInput = "";
    $scope.isSaving = false;

    /*--------------------------------------------------------------------------
    Definitions
    --------------------------------------------------------------------------*/
    $scope.PopulateSelectedTracker = PopulateSelectedTracker;
    $scope.SaveTrackingCode = SaveTrackingCode;
    $scope.closeToast = closeToast;

    API.get("/settings")
        .then((response) => {
            var settings = response.data;
            for (var s = 0; s < settings.length; s++){
                if(settings[s].key.includes("tracking.client")){
                    var caption = settings[s].key
                    if (settings[s].key ="tracking.client.ga"){
                        var caption = "Google Analytics Tracker Code"
                    }
                    $scope.trackerCodes.push({
                        "key":settings[s].key,
                        "caption":caption,
                        "value": settings[s].value
                    });
                }        
            }            
        });

    /*--------------------------------------------------------------------------
    Implementations
    --------------------------------------------------------------------------*/    

    function PopulateSelectedTracker(selectedTrackerOption){
        $scope.trackerCodeInput = selectedTrackerOption.value;
    }

    function SaveTrackingCode(selectedTrackerOption){
        $scope.isSaving = true;
        API.post('/admin/settings', {"key":selectedTrackerOption.key,"value":$scope.trackerCodeInput})
            .catch((errorResponse) => {
                $scope.toastClass = "alert-danger";
                $scope.toastMessage = errorResponse.data.message;
                $scope.isSaving = false;
            });
        $scope.toastClass = "alert-success";
        $scope.toastMessage = "Google code Successfully Updated";
        $scope.isSaving = false;            
    }

    function closeToast() {
        $scope.toastMessage = null;
        $scope.toastClass = null;
    }  
}

export default {
    name: 'GoogleAnalyticsCtrl',
    fn: GoogleAnalyticsCtrl
}