import angular from 'angular';
import moduleLoader from '../utils/loader';

const bulk = require('bulk-require');
const directivesModule = angular.module('app.directives', []);
const directives = bulk(__dirname, ['./**/!(*index|*.spec).js']);

moduleLoader(directivesModule, 'directive', directives, true);

export default directivesModule;
