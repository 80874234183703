// @ngInject
function HomeCtrl($scope, $http, $window, Session, API, $timeout, Auth) {
    var captions = Session.getJSON('captions');
    
    $scope.tagHomeTitle = captions["home_title"];
    $scope.tagHomeBody = captions["home_body"];
    $scope.tagResourcesTitle = captions["resources_title"];
    $scope.url = API.url() + "/assets";
    var user = Session.getJSON("user");
    

    $scope.assets = {};
    $scope.resource_actions = [
        {
            class: 'btn btn-icon small-fixed btn-primary',
            icon: 'download',
            action: viewLink,
            requires: [
                { field: 'file', value: true }
            ]
        },
        {
            class: 'btn btn-icon small-fixed btn-primary',
            icon: 'chevron-right',
            action: viewLink,
            requires: [
                { field: 'file', value: false, default: true }
            ]
        }
    ]

    API.get("/assets")
        .then((response) => {
            $scope.assets = response.data;
        }, function (error) {
            $scope.toastMessage = error.data.message;
            $scope.toastClass = "alert-danger";
            $timeout(() => {
                $scope.loading = false;
            }, 100);
        });

    /*--------------------------------------------------------------------------
    Definitions
    --------------------------------------------------------------------------*/
    $scope.downloadLink = downloadLink;
    $scope.viewLink - viewLink;
    $scope.closeToast = closeToast;
    $scope.getAsset = getAsset;   
    $scope.logUrlAccess = logUrlAccess;

    /*--------------------------------------------------------------------------
    Implementations
    --------------------------------------------------------------------------*/
    function viewLink($event, item) {
        $window.open(item.url);
    }

    function downloadLink($event, asset) {
        console.log("Asset: ", asset);

        // ng-href=`http://localhost:32972/api/v1/visits/${visitId}/letters/?attachmentName=${asset.file_name}`
        API.get(`/assets/${asset.id}`);
    }

    function closeToast() {
        $scope.toastMessage = null;
        $scope.toastClass = null;
    }

    function getAsset(assetId) {
        Auth.getResource(`/assets/${assetId}/file?`, user.user_id)
            .then(null, () => {
                console.log("Failure");
            });    
    }    

    function logUrlAccess(assetId, url){
        Auth.logUrl(`/assets/${assetId}/url?`, user.user_id, url)
            .then(null,() =>{
                console.log("Failure");
            } );
    }

}

export default {
    name: 'HomeCtrl',
    fn: HomeCtrl
};
