// @ngInject
function RecEditCtrl($scope, Inspection, $state, $http, Lightbox, API, moment, Auth, $uibModal, Upload, $timeout, Session) {

    // ---Scope variables 
    
    $scope.rec = {};
    $scope.loading = true;
    $scope.comments = {};
    $scope.fields = {};
    var recId = $state.params.id;
    var photoModal = null;
    var deletePhotoModal = null;
    var captions = Session.getJSON('captions');
    $scope.userInfo = Session.getJSON('user');
    $scope.recommendation_comment = captions['recommendation_comment']
    $scope.rci_recommendation_photos = captions['rci_recommendation_photos']
    $scope.ip_recommendation_photos = captions['ip_recommendation_photos']
    $scope.update_recommendations = captions['update_recommendations']
    $scope.isUploading = false;

    

    /*--------------------------------------------------------------------------
    Definitions
    --------------------------------------------------------------------------*/
    $scope.ipPhotos = [];
    $scope.rciPhotos = [];
    $scope.addedPhoto = {};
    $scope.parseDate = parseDate;
    $scope.AddComment = AddComment;
    $scope.openPhotoModal = openPhotoModal;
    $scope.closeDeleteModal = closeDeleteModal;
    $scope.openDeletePhotoModal = openDeletePhotoModal;
    $scope.deleteRecPhoto = deleteRecPhoto;
    $scope.addPhoto = addPhoto;
    $scope.removePhoto = removePhoto;
    $scope.closeModal = closeModal;
    $scope.closeToast = closeToast;

    API.get("/recs/" + recId)
        .then((response) => {
            var body = response.data;
            $scope.rec = body.data;
            $scope.comments = body.data.comments;
            $scope.fields = body.data.fields;

            for (var i = 0 ; i < body.data.photos.length; i++) {
                var photo = body.data.photos[i];
                if (!photo.carrier) {
                    $scope.ipPhotos.push({
                        url: photo.path,
                        carrier: photo.carrier,
                        id: photo.id,
                        caption: photo.description
                    });
                } else {
                    $scope.rciPhotos.push({
                        url: photo.path,
                        carrier: photo.carrier,
                        id: photo.id,
                        caption: photo.description
                    });
                }

            }
            $timeout(() => {
                $scope.loading = false;
            }, 100);
        }
        ,function(error) {
            $scope.toastMessage = error.data.message;
            $scope.toastClass = "alert-danger";
            $timeout(() => {
                $scope.loading = false;
            }, 100);            
        });

    $scope.openIpLightboxModal = function(index) {
        Lightbox.templateUrl = "templates/lightbox.html"
        Lightbox.openModal($scope.ipPhotos, index);
    };
    $scope.openRCILightboxModal = function(index) {
        Lightbox.templateUrl = "templates/lightbox.html"
        Lightbox.openModal($scope.rciPhotos, index);
    };

    /*--------------------------------------------------------------------------
    Implementations
    --------------------------------------------------------------------------*/
    function AddComment(comment) {
        var addedComment = comment;
        $scope.commentInput = null;

        API.post("/recs/" + recId + "/comments", { comment: addedComment })
            .then((response) => {
                $scope.comments.push(response.data);
            });
        API.post("/email/recommendation/comment/" + recId)
            .then("",function(error){
                $scope.toastMessage = error.data.message;
                $scope.toastClass = "alert-danger";
            });            
    }

    function parseDate(dateString) {
        // POLICY: prevent displaying 0
        if (!dateString || dateString == '0' || dateString == '') { return '-'; }

        return moment(dateString, "YYYYMMDD").format("YYYY-MMM-DD");
    }

    function closeModal() {
        photoModal.close();
    }

    function closeDeleteModal() {
        deletePhotoModal.close();
    }

    function openPhotoModal() {
        $scope.isUploading = false;
        $scope.addedPhoto = {};
        photoModal = $uibModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'controllers/recs/photoAddModal.html',
            size: "sm",
            scope: $scope,
            windowClass: 'files-modal'
        });
    }

    function openDeletePhotoModal(image, imageIndex) {
        $scope.deletedImageId = image.id;
        $scope.imageIndex = imageIndex;
        deletePhotoModal = $uibModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'controllers/recs/recPhotoConfirmDelete.html',
            size: "sm",
            scope: $scope,
            windowClass: 'files-modal'
        });
    }

    function deleteRecPhoto(deletedImageId, imageIndex) {
        API.delete("/recs/" + recId + "/files/" + deletedImageId)
            .then((response) => {
                deletePhotoModal.close();
                $scope.ipPhotos.splice(imageIndex, 1);
            });
    }

    function removePhoto() {
        $scope.addedPhoto = {};
    }

    function closeToast() {
        $scope.toastMessage = null;
        $scope.toastClass = null;
    }

    function addPhoto() {
        $scope.toastMessage = null;

        if ($scope.addedPhoto != {}) {
            $scope.isUploading = true;
            Upload.upload({
                url: API.url() + '/recs/' + recId + "/files",
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + Auth.getToken()
                },
                data: {
                    'filename': $scope.addedPhoto.file.name,
                    'description': $scope.addedPhoto.description,
                    'file': $scope.addedPhoto.file
                }
            })
                .then(
                function(response) {
                    var photoData = response.data;
                    var photo = {
                        id: photoData.id,
                        carrier: photoData.carrier,
                        url: `${API.url()}/recs/${recId}/files/${photoData.id}?carrier=${photoData.carrier}&userId=${$scope.userInfo["user_id"]}`,
                        caption: $scope.addedPhoto.description
                    }
                    $scope.ipPhotos.push(photo)
                    closeModal();
                },
                function(error) {
                    $scope.toastMessage = error.data.message;
                    $scope.toastClass = "alert-danger";
                    closeModal();
                }
                );
            API.post("/email/recommendation/comment/" + recId)
                .then("",function(error){
                    $scope.toastMessage = error.data.message;
                    $scope.toastClass = "alert-danger";
                });
        }
    }
}

export default {
    name: 'RecEditCtrl',
    fn: RecEditCtrl
};
