import angular from 'angular';
import moduleLoader from '../utils/loader';

const bulk = require('bulk-require');
const controllersModule = angular.module('app.controllers', []);
const controllers = bulk(__dirname, ['./**/!(*index|*.spec).js']);

moduleLoader(controllersModule, 'controller', controllers, true);

export default controllersModule;
