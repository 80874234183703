// @ngInject
function AccountCtrl($scope, $http, $window, $uibModal, Session, API, $timeout) {
    var captions = Session.getJSON('captions');
    var user = Session.getJSON('user');

    $scope.accountSummaryTitle = captions.my_account_title;
    $scope.addressCaption = "Address";
    $scope.phoneCaption = "Phone";
    var changePasswordModal = null;
    $scope.errors = null;
    $scope.accountInfo = {};
    $scope.changePasswordInfo = {
        'old_password': "",
        'new_password': "",
        'Email': user.email
    };

    $scope.assets = {};

    API.get('/account')
        .then((response) => {
            $scope.accountInfo = response.data;
        }, function (error) {
            $scope.toastMessage = error.data.message;
            $scope.toastClass = "alert-danger";
            $timeout(() => {
                $scope.loading = false;
            }, 100);
        });


    /*--------------------------------------------------------------------------
    Definitions
    --------------------------------------------------------------------------*/
    $scope.closeToast = closeToast;
    $scope.OpenChangePasswordModal = OpenChangePasswordModal;
    $scope.ChangePassword = ChangePassword;
    $scope.closeModal = closeModal;

    /*--------------------------------------------------------------------------
    Implementations
    --------------------------------------------------------------------------*/

    function closeToast() {
        $scope.toastMessage = null;
        $scope.toastClass = null;
    }

    function closeModal() {
        changePasswordModal.close();
    }

    function OpenChangePasswordModal() {
        $scope.errors = null;
        $scope.changePasswordInfo = {
            'old_password': "",
            'new_password': "",
            'Email': user.email
        };
        changePasswordModal = $uibModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'controllers/account/changePasswordModal.html',
            size: "sm",
            scope: $scope,
            windowClass: 'files-modal'
        });
    }

    function ChangePassword() {
        $scope.errors = null;
        API.post("/changepassword", $scope.changePasswordInfo)
            .then((response) => {
                changePasswordModal.close()
                $scope.toastMessage = response.data.message;
                $scope.toastClass = "alert-success";                
            })
            .catch((errorResponse) => {
                $scope.errors = errorResponse.data.message;
            });
    }
}

export default {
    name: 'AccountCtrl',
    fn: AccountCtrl
};
