// @ngInject
function InvitationCtrl($scope, $http, $state, API, Auth, Caption){
    var token = $state.params.token;
    $scope.user = {
        "token": token,
        "email": "",
        "password": ""
    };

    $scope.errors = null;

    /*--------------------------------------------------------------------------
    Definitions
    --------------------------------------------------------------------------*/
    $scope.confirm = confirm;  


    /*--------------------------------------------------------------------------
    Implementations
    --------------------------------------------------------------------------*/
    function confirm(){
        $scope.errors = null;

        API.post('/confirm',$scope.user).
            then((confirmResponse) => {
                var loginCredentials = {
                    "username": $scope.user.email,
                    "password": $scope.user.password, //TODO: ADD HASH
                    "type": "User"
                }
                
                Auth.login(loginCredentials,'bearer')
                    .then((loginResponse) => {
                        Caption.getAll()
                        .then(() => {
                            $state.go('app.home');
                        }, errorHandler)
                    });
            }, errorHandler);
    }

    function errorHandler(error) {
        $scope.errors = error.data.message;
    }

}

export default {
    name: 'InvitationCtrl',
    fn: InvitationCtrl
}