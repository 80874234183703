// @ngInject
function AdminCtrl($scope, $rootScope, Session, $state) {
    $rootScope.rootClass = 'app';
    $scope.loading = true;

    if(!Session.get("auth")){
        $state.go("adminLogin");
    }

}

export default {
    name: 'AdminCtrl',
    fn: AdminCtrl
};
