// @ngInject
function SideBarDirective(Auth, $state, $http, Session) {
    function Link(scope) {
        var captions = Session.getJSON("captions");
        scope.showMenu = false;
        scope.navItems = [
            {name: captions["admin_home"] == ""? "admin_home":captions["admin_home"], sref: 'admin.home'},
            {name: captions["terminology"] == ""? "terminology":captions["terminology"], sref: 'admin.captions'},
            {name: captions["digital_assets"] == ""? "digital_assets":captions["digital_assets"], sref: 'admin.assets'},
            {name: 'Analytics', subItems: [{ name:'Google Analytics', sref:'admin.analytics'},{ name:'Reports', sref:'admin.reports'}], sref:'none'},
            {name: 'Legal', sref: 'admin.legal'}
        ];

        // Functions
        // TODO: Implement auth service
        //scope.logout = Auth.logout;

        scope.logout = logout;
        scope.toggleMenu = toggleMenu;
        scope.contains = contains;

        function toggleMenu() {
            scope.showMenu = !scope.showMenu;
        }

        function contains(route) {
            return ($state.current.name.indexOf(route) > -1 || route.indexOf($state.current.name) > -1);
        }

        function logout(){
            Session.remove("auth");
            Session.remove("user");
            $state.go('login');
            delete $http.defaults.headers.common.Authorization;
        }   
    }

    return {
        restrict: 'E',
        templateUrl: 'directives/sidebar/sidebar.html',
        scope: {
            title: '@',
            message: '@clickMessage'
        },
        link: Link
    };
}

export default {
    name: 'sideBar',
    fn: SideBarDirective
};
