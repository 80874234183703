// @ngInject
function VisitsCtrl($scope, $http, $log, $uibModal, API, Session, Auth, moment, Upload, $timeout ,$state) {
    $scope.loading = true;

    var captions = Session.getJSON("captions")
    $scope.tagUpcomingVisitsTitle = captions.visits_upcoming_title;
    $scope.tagPastVisits = captions.visits_past_title;
    $scope.visits_communications = captions['visits_communications']
    $scope.visits_attachments = captions['visits_attachments']

    var logger = $log.setContext('VisitsCtrl');
    var modal = null;
    var attachmentModal = null;
    $scope.url = API.url();

    $scope.upcoming_visits = {};
    $scope.past_visits = {};
    $scope.attachments = {};
    $scope.selectedVisit = {};
    $scope.columns = {};
    $scope.actions = [
        {
            icon: 'chevron-right',
            class: 'btn btn-primary',
            action: viewVisit,
            title: 'View'
        }        
    ];

    console.log($scope.actions);

    $scope.files = {};

    $scope.file_actions = [
        {
            class: 'btn btn-primary btn-icon small-fixed',
            icon: 'download',
            // action: downloadFile,
            url: true
        }
    ]

    API.get("/visits")
        .then((response) => {
            $scope.past_visits = response.data.past;
            $scope.upcoming_visits = response.data.upcoming;
            $scope.columns = response.data.columns;
            $timeout(() => {
                $scope.loading = false;
            }, 50);
        }, function (error) {
            $scope.toastMessage = error.data.message;
            $scope.toastClass = "alert-danger";
            $timeout(() => {
                $scope.loading = false;
            }, 100);
        });

    function disableLetterAction(visits) {
        for (var v = 0; v < visits.length; v ++) {
            var visit = visits[v];
            visit.disableAction = visit.LetterCount < 1;
        }

        return visits;
    }

    /*--------------------------------------------------------------------------
    Definitions
    --------------------------------------------------------------------------*/
    $scope.viewVisit = viewVisit;    
    $scope.closeToast = closeToast;

    /*--------------------------------------------------------------------------
    Implementations
    --------------------------------------------------------------------------*/


    function viewVisit($event, visit) {
        console.log("View:", visit);
        $state.go('app.visit', { id: visit.id });
    }

    function closeToast() {
        $scope.toastMessage = null;
        $scope.toastClass = null;
    }
}

export default {
    name: 'VisitsCtrl',
    fn: VisitsCtrl
};
