/**
 *  @description
 *  RCTTableDirective: renders a dynamic table based on columns and content
 *  Allows user to sort any fields.
 *  <br />
 *  Headers are tagged with a class <header-{{code}}> to allow overrides in CSS
 *  @module RCTTableDirective
 */
var template = `
<div class="{{class}}">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th ng-repeat="item in columns"
                    ng-click="sortTable(item.code)"
                    class="filter-header header-{{item.code}}"
                    ng-if="!item.hidden_head">
                    {{::item.caption}}
                    <icon name="caret-up" class="filter-icon" ng-if="checkFilter(item.code) === -1"></icon>
                    <icon name="caret-down" class="filter-icon" ng-if="checkFilter(item.code) === 1"></icon>
                </th>
                <th class='text-right'  
                ng-repeat="action in actions">
                {{action.caption}}</th>
            </tr>
        </thead>
        <tbody>
            <tr ng-repeat="item in content | orderBy:order">
                <td ng-repeat="column in columns"
                    ng-if="!column.hidden_body"
                    class="body-{{column.code}}">
                    <span ng-if="column.type === 'date'">{{parseDate(item[column.code])}}</span>
                    <span ng-if="column.type !== 'date'">{{item[column.code].length > 50 ? item[column.code].substring(0,50) + '...' : item[column.code] }}</span>
                </td>
                <td class='text-right' ng-if="actions && actions.length > 0">
                    <button ng-repeat="action in actions"
                        class="{{action.class}}"
                        ng-click="fire($event, action, item)"
                        ng-if="checkActionRequirements(action, item)">                        
                        <span ng-if="action.title"> {{action.title}}</span>
                        <icon name="{{action.icon}}" ng-if="action.icon"></icon>                        
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

/**
 *  @description
 *  Directive
 *  @constructor RCTTableDirective
 *  @return {number} 1 ascending, 0 no match, -1 descending
 */
// @ngInject
function RCTTableDirective(moment) {
    function Link(scope, element, attributes) {
        scope.order = [];
        var orderMap = {};

        scope.class = attributes.class || '';
        scope.class += ' table-responsive';

        /*--------------------------------------------------------------------------
        Definitions
        --------------------------------------------------------------------------*/
        scope.checkFilter = checkFilter;
        scope.sortTable = sortTable;
        scope.fire = fire;
        scope.checkActionRequirements = checkActionRequirements;
        scope.parseDate = parseDate;

        /*--------------------------------------------------------------------------
        Implementations
        --------------------------------------------------------------------------*/
        /**
         *  @description
         *  Checks filter to display the proper caret
         *  @function checkFilter
         *  @return {number} 1 ascending, 0 no match, -1 descending
         */
        function checkFilter(property, order) {
            if (orderMap.hasOwnProperty(property)) {
                if (orderMap[property]) { return 1; }
                else { return -1; }
            }

            return 0;
        }

        /**
         *  @description
         *  Click handler, checks if current property is in orderMap and toggle
         *  state, otherwise adds the new property in descending state.
         *  @function sortTable
         */
        function sortTable(property) {
            // check if it exists
            if (!orderMap.hasOwnProperty(property)) {
                orderMap[property] = true;
            } else {
                // CASE: toggles: asc > desc > off
                if (orderMap[property]) {
                    orderMap[property] = false;
                } else {
                    delete orderMap[property];
                }
            }

            updateSort();
        }

        /**
         *  @description
         *  Post-function, updates the order array for DOM
         *  @function updateSort
         */
        function updateSort() {
            var order = [];

            for (var key of Object.keys(orderMap)) {
                if (orderMap[key]) {
                    order.push(key);
                } else {
                    order.push("-" + key);
                }
            }

            scope.order = order;
        }

        /**
         *  @description
         *  Fires an action associated with the action button
         *  @function fire
         */
         function fire($event, handler, item) {
             if (!handler.action || typeof handler.action !== 'function') {
                 return;
             }

             handler.action($event, item);
         }

         /**
          *  @description
          *  Allows action to indicate when it should be included based on
          *  requirements on item fields
          *  @function checkActionRequirements
          */
         function checkActionRequirements(action, item) {
             if (item.disableAction) { return false; }
             if (!action.requires) { return true; }

             for (var require of action.requires) {
                 // CASE: property doesn't exist on item
                 if (!item.hasOwnProperty(require.field)) {
                     return require.default;
                 }

                 // CASE: property exists on item
                 if (item[require.field] == require.value) {
                     return true;
                 }
             }

             return false;
         }

         /**
          *  @description
          *  Parses a field if it is a date type
          *  @function parseDate
          */
         function parseDate(dateString) {
             // POLICY: prevent displaying 0
             if (dateString == '0') { return '-'; }

             return moment(dateString, "YYYYMMDD").format("YYYY-MMM-DD");
         }
    }

    function Controller() {

    }

    return {
        restrict: 'E',
        scope: {
            columns: '=',
            content: '=',
            actions: '='
        },
        template: template,
        link: Link
    }
}

export default {
    name: 'rctTable',
    fn: RCTTableDirective
}
