// import angular from 'angular';
import loader from '../utils/loader';

const bulk = require('bulk-require');
const constantsModule = angular.module('ada.constants', []);
const constants = bulk(__dirname, ['./**/!(*index|*.spec).js']);

loader(constantsModule, 'constant', constants);

export default constantsModule;
