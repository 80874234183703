// @ngInject
function AuthInterceptor($q, Session) {
    return {
        request: (config) => {
            console.log("Config:", config);
            config.headers.Authorization = 'Bearer ';

            return config;
        }
    }
}

export default {
    name: 'AuthInterceptor',
    fn: AuthInterceptor
};
