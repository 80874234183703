// @ngInject
function AdminHeaderBarDirective(Auth, API, $state, $http, $uibModal, Session, Upload, $window) {
    function Link(scope) {
        scope.showMenu = false;
        var user = Session.getJSON("user")
        scope.userName = user["first_name"] + " " + user["last_name"]
        scope.navItems = [
            { name: 'Home', sref: 'app.home' },
            { name: 'Recommendations', sref: 'app.recs' },
            { name: 'Visits', sref: 'app.visits' },
            { name: 'Resources', sref: 'app.resources' },
            { name: 'My Account', sref: 'app.account' }
        ];

        // Functions
        // TODO: Implement auth service
        //scope.logout = Auth.logout;
        scope.addedPhoto = {};
        var photoModal = null;

        scope.logout = logout;
        scope.toggleMenu = toggleMenu;
        scope.contains = contains;
        scope.openUploadCompanyLogoModal = openUploadCompanyLogoModal;
        scope.closeModal = closeModal;
        scope.removePhoto = removePhoto;
        scope.addPhoto = addPhoto;


        function toggleMenu() {
            scope.showMenu = !scope.showMenu;
        }

        function contains(route) {
            return ($state.current.name.indexOf(route) > -1 || route.indexOf($state.current.name) > -1);
        }

        function logout() {
            Auth.reset();
            //Session.remove("auth");
            $window.localStorage.removeItem("auth");
            Session.remove("user");
            $state.go('adminLogin');
            delete $http.defaults.headers.common.Authorization;
        }

        function openUploadCompanyLogoModal() {
            scope.addedPhoto = {};
            scope.errors = null;
            photoModal = $uibModal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'directives/adminHeader/uploadLogo.html',
                size: "sm",
                scope: scope
            });
        }

        function closeModal() {
            if (photoModal != null) {
                photoModal.close();                
            }
        }

        function removePhoto() {
            scope.addedPhoto.file = null;
        }

        function addPhoto() {
            if (scope.addedPhoto != {}) {
                scope.isUploading = true;
                Upload.upload({
                    url: API.url() + '/admin/uploadlogo/',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + Auth.getToken()
                    },
                    data: {
                        'file': scope.addedPhoto.file
                    }
                })
                    .then(
                    function (response) {
                        closeModal();
                        scope.isUploading = false;
                    },
                    function(error){
                        scope.errors = error.data.message;
                        scope.isUploading = false;                        
                    });
            }
        }
    }

    return {
        restrict: 'E',
        templateUrl: 'directives/adminHeader/adminHeader.html',
        scope: {
            title: '@',
            message: '@clickMessage'
        },
        link: Link
    };
}

export default {
    name: 'adminHeaderBar',
    fn: AdminHeaderBarDirective
};
