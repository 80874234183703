// @ngInject
function LoginCtrl($scope, $rootScope, $stateParams, $http, Session, $state, API, Auth, Caption, Setting, $log, $window) {
    var logger = $log.setContext("LoginCtrl");
    $scope.isAdmin = $state.current.name === 'adminLogin';

    $scope.adminTitle = $scope.isAdmin ? 'Admin ' : '';

    $scope.loginData = {
        username: '',
        password: '',
        type: $scope.isAdmin ? 'Admin' : 'User'
    };

    $rootScope.rootClass = '';

    logger.info("Auth.hasValidSession(): ", Auth.hasValidSession());
    if (Auth.hasValidSession()){
        if ($stateParams.res != null) {
            switch ($stateParams.res) {
                case 'recs':
                    $state.go('app.rec', { id: $stateParams.id });
                    break;

                case 'visits':
                    $state.go('app.visit', { id: $stateParams.id })
                    break;

                case 'resources':
                    logger.info("$stateParams.assetType: ", $stateParams.assetType);
                    if ($stateParams.assetType == 'file') {
                        Auth.getResource(`/assets/${$stateParams.id}/file?`, 0)
                            .then(null, () => {
                                console.log("Failure");
                            });
                    }
                    if ($stateParams.assetType == 'url') {
                        //window.op;
                    }
                    $state.go('app.resources')
            }
        }
        else if ($scope.isAdmin) {
            $state.go('admin.home');
            Session.set('isAdmin', $scope.isAdmin);
        } else {
            $state.go('app.home');
        }        
    }


    // Functions
    $scope.login = login;

    // login: makes an attempt at logging user against API and handles success/fail
    // TODO: handle real auth
    // TODO: show spinner and lock entry
    // TODO: add promise canceler
    function login(loginData) {
        logger.debug("login()", loginData);

        $scope.errors = null;

        // TODO: remove after OPTIONS support
        // $state.go('app.home');
        Auth.login(loginData,'bearer')
            .then(
                (response) => {
                    Setting.getAll();
                    Caption.getAll()
                        .then(() => {
                            if ($stateParams.res != null) {
                                switch($stateParams.res) {
                                case 'recs':
                                    $state.go('app.rec', { id: $stateParams.id });
                                    break;

                                case 'visits':
                                    $state.go('app.visits', { id:$stateParams.id })
                                    break;

                                case 'resources':
                                    console.log("$stateParams.assetType: ",$stateParams.assetType);
                                    if ($stateParams.assetType == 'file'){
                                    Auth.getResource(`/assets/${$stateParams.id}/file?`, 0)
                                        .then(null, () => {
                                            console.log("Failure");
                                        });
                                    }
                                    if ($stateParams.assetType == 'url'){
                                        //window.op;
                                    }
                                    $state.go('app.resources')                        
                                }
                                    
                            }
                            else if ($scope.isAdmin) {
                                $state.go('admin.home');
                                Session.set('isAdmin', $scope.isAdmin);
                            } else {
                                $state.go('app.home');
                            }
                        });
                },
                (response) => {
                    if (response.status === 401) {
                        $scope.errors = "Invalid Username/Password";
                    } else {
                        $scope.errors = "Unable to login";
                    }
                }
            );
    }
}

export default {
    name: 'LoginCtrl',
    fn: LoginCtrl
};
