import Logger from '../utils/logger';

/**
 *  SessionService: handles storing cached access
 *  @module SessionService
 */
 // @ngInject
function SessionService($log, $window) {
    var storage = null;
    var logger = Logger('ada.SessionService', $log);

    /**
    * Initializes the service by checking if there is a user with a valid session
    * @function init
    * @param {string} storageType - local | session (default: local)
    */
    function init(storageType) {
        logger.debug("Session.init()");

        switch (storageType) {
            case 'session':
                storage = $window.sessionStorage;
                break;
            case 'local':
            default:
                storage = $window.localStorage;
                break;
        }
    }

    /**
    * Sets a string value into storage
    * @function set
    * @param {string} key - the item's key
    * @param {string} value - value to be stored
    */
    function set(key, value) {
        if (!storage) { init(); }

        return storage.setItem(key, value);
    }

    /**
    * Retreives a string value from storage
    * @function get
    * @param {string} key - the item's key
    * @return {string} value | undefined
    */
    function get(key) {
        if (!storage) { init(); }

        return storage.getItem(key);
    }

    /**
    * serializes any object to JSON string before saving to key-value storage
    * uses angular.toJson to strip any angular internal attributes
    * @function setJSON
    * @param {string} key - the item's key
    * @param {string} obj
    */
    function setJSON(key, obj) {
        if (!storage) { init(); }

        return storage.setItem(key, angular.toJson(obj));
    }

    /**
    * If key exists, will deserialize and return the key value as JSON
    * @function getJSON
    * @param {string} key - the item's key
    * @return {string} obj | null
    */
    function getJSON(key) {
        if (!storage) { init(); }

        let obj = storage.getItem(key);
        if (!obj) { return null; }
        return JSON.parse(obj);
    }

    /**
    * Clears storage keys
    * @function clear
    */
    function clear() {
        if (!storage) { init(); }

        storage.clear();
    }

    /**
    * Remove a key from storage
    * @function remove
    */
    function remove(key) {
        storage.removeItem(key);
    }

    return {
        init: init,
        set: set,
        get: get,
        setJSON: setJSON,
        getJSON: getJSON,

        remove: remove,
        clear: clear
    };
}

export default {
    name: 'Session',
    fn: SessionService
}
