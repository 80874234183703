// import angular from 'angular';
import loader from '../utils/loader';

const bulk = require('bulk-require');
const directivesModule = angular.module('ada.directives', []);
const directives = bulk(__dirname, ['./**/!(*index|*.spec).js']);

loader(directivesModule, 'directive', directives);

export default directivesModule;
