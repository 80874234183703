// @ngInject
function AssetCtrl($scope, $http, $window, $state, API, $uibModal, $log, Auth, Upload, Session) {
    var assetId = $state.params.id;
    var captions = Session.getJSON("captions")    
    
    $scope.isNew = $state.current.name === 'admin.addAsset';
    var user = Session.getJSON("user");
    var modal = null;

    $scope.assetCaption = captions["digital_asset"];
    $scope.asset = {};
    $scope.availableTags = [];
    $scope.selectedTags = [];
    $scope.currentlySavedTags = [];
    $scope.isSaving = false;

    $scope.showThumb = false;
    $scope.showIcon = false;
    $scope.assetIcon = 'pdf';

    $scope.saveAsset = saveAsset;
    $scope.confirmDelete = confirmDelete;
    $scope.deleteAsset = deleteAsset;
    $scope.closeModal = closeModal;
    $scope.getAssetExtension = getAssetExtension;
    $scope.removePhoto = removePhoto;
    $scope.setupAssetImage = setupAssetImage;

    $scope.isRequired = isRequired;

    if ((!assetId || isNaN(assetId)) && !$scope.isNew) {
        $state.go('admin.assets');
        return;
    }

    if (!$scope.isNew) { // Existing asset

        API.get('/assets/' + $state.params.id)
            .then((assetResponse) => {
                $scope.currentlySavedTags = angular.copy(assetResponse.data.tags);
                $scope.asset = assetResponse.data;
                var selectedTags = assetResponse.data.tags;
                $scope.selectedTags = assetResponse.data.tags;

                API.get('/tags')
                    .then((tagsResponse) => {
                        var tags = tagsResponse.data;

                        for (var t = 0; t < selectedTags.length; t++) {
                            var tag = selectedTags[t];
                            for (var i = 0; i < tags.length; i++) {
                                if (tags[i].id === tag.id) {
                                    tags.splice(i, 1);
                                    break;
                                }
                            }
                        }

                        $scope.availableTags = tags;
                    })

                if ($scope.asset.asset_type == 'file') {
                    API.post('/access_token', {
                        "path": API.url() + '/assets/' + $scope.asset.id,
                        "user_id": user.user_id
                    })
                        .then((tokenResponse) => {
                            $scope.asset.file = API.url() + '/assets/' + $scope.asset.id + '/file?&accessToken=' + tokenResponse.data.token;
                            setupAssetImage($scope.asset.file_name);                            
                        });
                }
            });
    } else { //New Asset
        API.get('/tags')
            .then((tagsResponse) => {
                $scope.availableTags = tagsResponse.data;
            })
    }

    $scope.dragControlListeners = {
        accept: function (sourceItemHandleScope, destSortableScope) { return true },//override to determine drag is allowed or not. default is true.
        itemMoved: function (event) { },
        orderChanged: function (event) { }
    };

    $scope.dragControlListeners1 = {
        containment: '#board'
    };


    function confirmDelete() {
        modal = $uibModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'controllers/assets/assetConfirmDelete.html',
            size: "sm",
            scope: $scope,
            windowClass: 'files-modal'
        });
    }

    function deleteAsset() {

        API.delete("/admin/assets/" + $scope.asset.id)
            .then((response) => {
                closeModal();
                $state.go('admin.assets');
            })
    }

    function getAssetExtension(fileName){
        if (!fileName){
            return '';
        }        


        return fileName.split('.').pop();
    }

    function closeModal() {
        modal.close();
    }

    function removePhoto() {
        $scope.asset.file = null;
        $scope.showThumb = false;
        $scope.showIcon = false;
        $scope.assetIcon = 'pdf';
    }

    // supports ng-required for form fields
    function isRequired() {
        if ($scope.asset.asset_type == 'url') {
            return !$scope.asset.url || $scope.asset.url.length == 0;
        } else if ($scope.asset.asset_type == 'file') {
            return !$scope.asset.file_name || $scope.asset.file_name.length == 0;
        }
    }

    function saveAsset() {
        if ($scope.assetForm.$invalid) {
            return;
        }

        $scope.isSaving = true;

        var selectedTagIds = '';
        for (var i = 0; i < $scope.selectedTags.length; i++) {
            selectedTagIds += $scope.selectedTags[i].id + ',';
        }
        selectedTagIds = selectedTagIds.substr(0, selectedTagIds.length - 1);


        var currentlySavedTagsIds = '';
        for (var i = 0; i < $scope.currentlySavedTags.length; i++) {
            currentlySavedTagsIds += $scope.currentlySavedTags[i].id + ',';
        }
        currentlySavedTagsIds = currentlySavedTagsIds.substr(0, currentlySavedTagsIds.length - 1);

        var newAssetIds = [];
        for (var i = 0; i < $scope.selectedTags.length; i++){
            if (currentlySavedTagsIds.indexOf($scope.selectedTags[i].id) == -1 ){
                newAssetIds.push($scope.selectedTags[i]);
            }
        }        


        if ($scope.isNew) {

            if ($scope.asset.asset_type == 'file') {  //File type asset
                Upload.upload({
                    url: API.url() + '/admin/assets',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + Auth.getToken()
                    },
                    data: {
                        'file': $scope.asset.file,
                        'name': $scope.asset.asset_name,
                        'type': $scope.asset.asset_type,
                        'file_name': $scope.asset.file_name,
                        'tags': selectedTagIds
                    }
                })
                    .then(
                    function (response) {
                        $state.go('admin.assets')
                        API.post("/email/asset/create/0", newAssetIds)
                            .then("",function(error){
                                $scope.toastMessage = error.data.message;
                                $scope.toastClass = "alert-danger";
                            });                            
                    },
                    function (error) {
                    }
                    );                   

            } else { //URL type asset

                Upload.upload({
                    url: API.url() + '/admin/assets',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + Auth.getToken()
                    },
                    data: {
                        'name': $scope.asset.asset_name,
                        'type': $scope.asset.asset_type,
                        'url': $scope.asset.url,
                        'tags': selectedTagIds
                    }
                })
                    .then(
                    function (response) {
                        $state.go('admin.assets')
                        API.post("/email/asset/create/0", newAssetIds)
                            .then("",function(error){
                                $scope.toastMessage = error.data.message;
                                $scope.toastClass = "alert-danger";
                            });                                 
                    },
                    function (error) {
                    }
                    );           
            }

        } else { //Existing Asset

            if ($scope.asset.asset_type == 'file') {  //File type asset
                var data = {
                    'name': $scope.asset.asset_name,
                    'type': $scope.asset.asset_type,
                    'file_name': $scope.asset.file_name,
                    'tags': selectedTagIds
                };

                var headers = {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + Auth.getToken()
                };

                if (typeof ($scope.asset.file) == "object") {
                    data.file = $scope.asset.file;
                }

                Upload.upload({
                    url: API.url() + '/admin/assets/' + $scope.asset.id,
                    method: 'PUT',
                    headers: headers,
                    data: data
                })
                    .then(
                    function (response) {
                        $state.go('admin.assets')
                        API.post("/email/asset/create/" +  $scope.asset.id, newAssetIds)
                            .then("",function(error){
                                $scope.toastMessage = error.data.message;
                                $scope.toastClass = "alert-danger";
                            });                            
                    },
                    function (error) {
                    }
                    );                    
            } else { //URL type asset

                Upload.upload({
                    url: API.url() + '/admin/assets/' + $scope.asset.id,
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + Auth.getToken()
                    },
                    data: {
                        'name': $scope.asset.asset_name,
                        'type': $scope.asset.asset_type,
                        'url': $scope.asset.url,
                        'tags': selectedTagIds
                    }
                })
                    .then(
                    function (response) {
                        $state.go('admin.assets')
                        API.post("/email/asset/create/" +  $scope.asset.id, newAssetIds)
                            .then("",function(error){
                                $scope.toastMessage = error.data.message;
                                $scope.toastClass = "alert-danger";
                            });                           
                    },
                    function (error) {
                    }
                    );                  
            }
        }
    }

    function setupAssetImage(file_name) {
        console.log("FileName:", file_name);
        console.log("getAssetExtension(file_name)", getAssetExtension(file_name));
        
        $scope.assetIcon = 'fa fa-file-o';
        $scope.showThumb = false;
        $scope.showIcon = false;
        
        switch (getAssetExtension(file_name)) {
            case 'pdf':
                $scope.assetIcon = 'fa fa-file-pdf-o'; 
                $scope.showIcon = true;
                break;
            case 'xlsx':
            case 'xls':
                $scope.assetIcon = 'fa fa-file-excel-o';
                $scope.showIcon = true;
                break;
            case 'docx':
            case 'doc':
                $scope.assetIcon = 'fa fa-file-word-o';
                $scope.showIcon = true;
                break;
            case 'pptx':
            case 'ppt' :
                $scope.assetIcon = 'fa fa-file-powerpoint-o';
                $scope.showIcon = true;                
            case 'zip':
            case '7z':
                $scope.assetIcon = 'fa fa-file-zip-o';
                $scope.showIcon = true;                            
            case 'jpeg':
            case 'png':
            case 'jpg':
            case 'gif':                     
                $scope.showThumb = true;
                break;
            default:
                $scope.showIcon = true;
                break;                            
        } 
    }    
}

export default {
    name: 'AssetCtrl',
    fn: AssetCtrl
}