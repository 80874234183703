import Logger from '../utils/logger';

/**
 *  APIService: handles API request, wraps $http
 *  @module APIService
 */
 // @ngInject
function APIService($http, $log, $q) {
    let auth;
    let host = '';
    let api = '';

    var logger = Logger('ada.APIService', $log);

    /**
    * Initializes API requests with host and api paths
    * @function init
    * @param {object} connection - {host, api}
    */
    function init(connection) {
        logger.debug("init()");

        return $q((resolve) => {
            if (!connection) {
                host = window.host || '';
                api = window.api || '';
            }

            logger.debug("host: ", host);
            logger.debug("api: ", api);

            resolve();
        });
    }

    /**
    * Returns url with host + api path
    * @function fullUrl
    * @return {string} url with api path
    */
    function fullUrl() {
        return host + api;
    }

    /**
    * @function get
    * @param {string} API resource
    * @param {object} options $http request options
    */
    function get(url, options) {
        return request('get', url, options);
    }

    /**
    * @function post
    * @param {string} API resource
    * @param {object} options $http request options
    */
    function post(url, options) {
        return request('post', url, options);
    }

    /**
    * @function put
    * @param {string} API resource
    * @param {object} options $http request options
    */
    function put(url, options) {
        return request('put', url, options);
    }

    /**
    * 'del' is used due to 'delete'being a reserved word in JS
    * @function del
    * @param {string} API resource
    * @param {object} options $http request options
    */
    function del(url, options) {
        return request('delete', url, options);
    }

    /**
    * decorates the options with Authorization header
    * @function authorize
    * @param {object} options $http request options
    */
    function authorize(options) {
        if (auth) {
            options.headers = {
                Authorization: 'Basic ' + auth
            };
        }
    }

    /**
    * A generalized request wrapper for $http
    * @function request
    * @param {string} method - GET | PUT | POST | DELETE
    * @param {string} url API resource
    * @param {object} options $http request options
    */
    function request(method, url, options) {
        options = options || {};
        authorize(options);
        return $http[method](fullUrl() + url, options);
    }

    return {
        init: init,
        url: fullUrl,
        host: () => { return host; },
        get: get,
        post: post,
        put: put,
        "delete": del
    }
}

export default {
    name: 'API',
    fn: APIService
}
