/**
 *  IconDirective: <icon name="{{name}}"></icon> using elusive icons
 *  @module IconDirective
 */
 // @ngInject
 function IconDirective() {
     var _icons = {
         'attachment': 'fa fa-paperclip',
         'caret-down': 'fa fa-caret-down',
         'caret-up': 'fa fa-caret-up',
         'chevron-right': 'fa fa-chevron-right',
         'close': 'fa fa-remove',
         'download': 'fa fa-download',
         'plus': 'fa fa-plus',
         'mail': 'fa fa-envelope',
         'pencil': 'fa fa-pencil',
         'link': 'fa fa-link',
         'trash': 'fa fa-trash',
         'check': 'fa fa-check-square-o',
         'repeat': 'fa fa-repeat',
         'file': 'fa fa-file',
         'key': 'fa fa-key',
         'phone': 'fa fa-phone',
         'calendar': 'fa fa-calendar',
         'pdf' : 'fa fa-file-pdf-o',         
         'docx' : 'fa fa-file-word-o',         
         'pptx' : 'fa fa-file-powerpoint-o',      
         'zip' : 'fa fa-file-zip-o',   
         'xlsx' : 'fa fa-file-excel-o'         
    }

    function IconLink(scope) {
        // try to match the name to predefined then give up and use the resulting class
        scope.iconClass = _icons[scope.name] || scope.name;
    }

     return {
         restrict: 'E',
         template: '<i class="icon {{iconClass}}"></i>',
         scope: {
             name: '@'
         },
         link: IconLink
     }
 }

export default {
    name: 'icon',
    fn: IconDirective
}
