// @ngInject
function ReportsCtrl($scope, API, Session, $uibModal, moment) {
  var captions = Session.getJSON('captions');
  $scope.assetsCaption = captions["digital_assets"] == ""? "digital_assets":captions["digital_assets"];

  $scope.assetLabels = [];
  $scope.assetData = [];

  $scope.loginData = [];
  $scope.login
  $scope.showLoginChart = false;
  var loginModal = null;

  $scope.commentData = [];
  $scope.rciCommentsVisible = false;
  $scope.ipCommentsVisible = false;
  $scope.RciCommentCount = 0;
  $scope.InsuredCommentCount = 0;

  $scope.assetStartDate = null;
  $scope.loginStartDate = null;
  $scope.commentStartDate = null;
  
  $scope.assetEndDate = null;
  $scope.loginEndDate = null;
  $scope.commentEndDate = null;

  $scope.dateSelections = [
    {
      id: 0,
      label: "Before"
    },
    {
      id: 1,
      label: "On or Before"
    },
    {
      id: 2,
      label: "After"
    },
    {
      id: 3,
      label: "On or After"
    },
    {
      id: 4,
      label: "Between"
    },
    {
      id: 5,
      label: "Equal"
    }];


  API.post("/reports/assetReport")
    .then((assetResponse) => {
      for (var a = 0; a < assetResponse.data.length; a++) {
        $scope.assetLabels.push(assetResponse.data[a].data.asset_name);
        $scope.assetData.push(assetResponse.data[a].log_count);
      }
    })

  API.post("/reports/loginReport")
    .then((loginResponse) => {
      $scope.loginData = loginResponse.data;
      $scope.loggedInUsersCount = Object.keys(loginResponse.data.logged_in_users).length;
      $scope.activeUserRatio = (Math.round((($scope.loggedInUsersCount / $scope.loginData.user_emails.length) * 100) * 100) / 100);
    })

  API.post("/reports/commentReport")
    .then((commentResponse) => {
      $scope.commentData = commentResponse.data;
      for (var prop in $scope.commentData.rci_comments) {
        $scope.RciCommentCount += $scope.commentData.rci_comments[prop].length;
      }
      for (var prop in $scope.commentData.insured_comments) {
        $scope.InsuredCommentCount += $scope.commentData.insured_comments[prop].length;
      }
    })

  /*--------------------------------------------------------------------------
  Definitions
  --------------------------------------------------------------------------*/

  $scope.filter = filter;
  $scope.openLoginModal = openLoginModal;
  $scope.closeModal = closeModal;
  $scope.parseDate = parseDate;
  $scope.parseTime = parseTime;
  $scope.today= today;

  /*--------------------------------------------------------------------------
  Implementations
  --------------------------------------------------------------------------*/

  function closeModal() {
    loginModal.close();
  }

  function parseDate(dateString) {
    // POLICY: prevent displaying 0
    if (!dateString || dateString == '0' || dateString == '') { return '-'; }
    return moment(dateString.substr(0, 10), "YYYYMMDD").format("YYYY-MMM-DD");
  }

  function parseTime(dateString) {
    return dateString.substr(11);
  }

  function openLoginModal(email) {
    $scope.currentEmail = email;
    $scope.loginList = $scope.loginData.logged_in_users[email];
    loginModal = $uibModal.open({
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: 'controllers/analytics/loginLogModal.html',
      size: "md",
      scope: $scope,
      windowClass: 'files-modal'
    });
  }

  function filter(filterTable, selectedDateFilter) {
    if (filterTable == 'asset') {

      if (selectedDateFilter.label == 'Between' && ($scope.assetStartDate == null || $scope.assetEndDate == null)){ 
          return;
      }
      var dateSelected = [$scope.assetStartDate];
      if ($scope.assetEndDate != null) {
        dateSelected.push($scope.assetEndDate);
      }

      if (selectedDateFilter != null && dateSelected[0] != null) {
        API.post("/reports/assetReport", { filter: selectedDateFilter.id, dates: dateSelected })
          .then((assetResponse) => {
            $scope.assetLabels = [];
            $scope.assetData = [];

            for (var a = 0; a < assetResponse.data.length; a++) {
              $scope.assetLabels.push(assetResponse.data[a].data.asset_name);
              $scope.assetData.push(assetResponse.data[a].log_count);
            }
          });
      }
    }

    if (filterTable == 'login') {
      if (selectedDateFilter.label == 'Between' && ($scope.loginStartDate == null || $scope.loginEndDate == null)){
          return;
      }        

      var dateSelected = [$scope.loginStartDate];   

      if ($scope.loginEndDate != null) {
        dateSelected.push($scope.loginEndDate);
      }
      if (selectedDateFilter != null && dateSelected[0] != null) {
        API.post("/reports/loginReport", { filter: selectedDateFilter.id, dates: dateSelected })
          .then((loginResponse) => {
            $scope.loginData = loginResponse.data;
            $scope.loggedInUsersCount = Object.keys(loginResponse.data.logged_in_users).length;
            $scope.activeUserRatio = (Math.round((($scope.loggedInUsersCount / $scope.loginData.user_emails.length) * 100) * 100) / 100);
          });
      }
    }

    if (filterTable == 'comment') {
      
      if (selectedDateFilter.label == 'Between' && ($scope.commentStartDate == null || $scope.commentEndDate == null)){
          return;
      }       

      var dateSelected = [$scope.commentStartDate];
      
      if ($scope.commentEndDate != null) {
        dateSelected.push($scope.commentEndDate);
      }
      if (selectedDateFilter != null && dateSelected[0] != null) {
        API.post("/reports/commentReport", { filter: selectedDateFilter.id, dates: dateSelected })
          .then((commentResponse) => {
            //reset the current count
            $scope.RciCommentCount = 0;
            $scope.InsuredCommentCount = 0;            
            $scope.commentData = commentResponse.data;
            for (var prop in $scope.commentData.rci_comments) {
              $scope.RciCommentCount += $scope.commentData.rci_comments[prop].length;
            }
            for (var prop in $scope.commentData.insured_comments) {
              $scope.InsuredCommentCount += $scope.commentData.insured_comments[prop].length;
            }
          });
      }
    }    
  }

  function today(dateType) {
    $scope.startDate = new Date();
  };

  $scope.clear = function (dateType) {
    if (dateType == "asset") {
      $scope.assetStartDate = null;
      $scope.assetEndDate = null;
    }
    if (dateType == "login") {
      $scope.loginStartDate = null;
      $scope.loginEndDate = null;
    }
    if (dateType == "comment") {
      $scope.commentStartDate = null;
      $scope.commentEndDate = null;
    }        
  };

  $scope.inlineOptions = {
    customClass: getDayClass,
    minDate: new Date(),
    showWeeks: true,
  };

  $scope.assetEndDateOptions = {
    formatYear: 'yy',
    maxDate: new Date(2020, 5, 22),
    minDate: $scope.assetStartDate != null ? $scope.assetStartDate : new Date(1991, 1, 1),
    startingDay: 1
  };

  $scope.assetStartDateOptions = {
    formatYear: 'yy',
    maxDate: $scope.assetEndDate != null ? $scope.assetEndDate : new Date(2020, 5, 22),
    minDate: new Date(1991, 1, 1),
    startingDay: 1
  };
  $scope.endDateOptions = {
    formatYear: 'yy',
    maxDate: new Date(2020, 5, 22),
    minDate: $scope.startDate != null ? $scope.startDate : new Date(1991, 1, 1),
    startingDay: 1
  };

  $scope.startDateOptions = {
    formatYear: 'yy',
    maxDate: $scope.endDate != null ? $scope.endDate : new Date(2020, 5, 22),
    minDate: new Date(1991, 1, 1),
    startingDay: 1
  };
  $scope.endDateOptions = {
    formatYear: 'yy',
    maxDate: new Date(2020, 5, 22),
    minDate: $scope.startDate != null ? $scope.startDate : new Date(1991, 1, 1),
    startingDay: 1
  };

  $scope.startDateOptions = {
    formatYear: 'yy',
    maxDate: $scope.endDate != null ? $scope.endDate : new Date(2020, 5, 22),
    minDate: new Date(1991, 1, 1),
    startingDay: 1
  };    

  $scope.assetDateOpen = function () {
    $scope.assetDate.opened = true;
  };
  $scope.assetDateToOpen = function () {
    $scope.assetToDate.opened = true;
  };

  $scope.loginDateOpen = function () {
    $scope.loginDate.opened = true;
  };
  $scope.loginDateToOpen = function () {
    $scope.loginToDate.opened = true;
  };
  $scope.commentDateOpen = function () {
    $scope.commentDate.opened = true;
  };
  $scope.commentDateToOpen = function () {
    $scope.commentToDate.opened = true;
  };  

  $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
  $scope.format = $scope.formats[0];
  $scope.altInputFormats = ['M!/d!/yyyy'];

  $scope.assetDate = {
    opened: false
  };
  $scope.assetToDate = {
    opened: false
  };
  $scope.loginDate = {
    opened: false
  };
  $scope.loginToDate = {
    opened: false
  };
  $scope.commentDate = {
    opened: false
  };
  $scope.commentToDate = {
    opened: false
  };  


  function getDayClass(data) {
    var date = data.date,
      mode = data.mode;
    if (mode === 'day') {
      var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

      for (var i = 0; i < $scope.events.length; i++) {
        var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

        if (dayToCheck === currentDay) {
          return $scope.events[i].status;
        }
      }
    }

    return '';
  }
}

export default {
  name: 'ReportsCtrl',
  fn: ReportsCtrl
}