import angular from 'angular';
import moduleLoader from '../utils/loader';

const bulk = require('bulk-require');
const filtersModule = angular.module('app.filters', []);
const filters = bulk(__dirname, ['./**/!(*index|*.spec).js']);

moduleLoader(filtersModule, 'filter', filters);

export default filtersModule;
