// @ngInject
function RecsCtrl($scope, Inspection, $http, $state, Session, API, $timeout) {
    $scope.loading = true;
    var captions = Session.getJSON("captions");
    $scope.tagOpenRecsTitle = captions.recommendations_open_title;
    $scope.tagCompletedRecsTitle = captions.recommendations_closed_title;
    

    $scope.open = {};
    $scope.completed = {};
    $scope.columns = {};
    $scope.actions = [
        {
            icon: 'chevron-right',
            class: 'btn btn-primary',
            action: viewRec,
            title: 'View'
        }
    ];
    API.get("/recs")
        .then((response) => {
            $scope.columns = response.data.columns;
            $scope.open = response.data.content.open;
            $scope.completed = response.data.content.closed;

            $timeout(() => {
                $scope.loading = false;
            }, 50);
        }, function (error) {
            $scope.toastMessage = error.data.message;
            $scope.toastClass = "alert-danger";
            $timeout(() => {
                $scope.loading = false;
            }, 100);
        });

    /*--------------------------------------------------------------------------
    Definitions
    --------------------------------------------------------------------------*/
    $scope.viewRec = viewRec;
    $scope.closeToast = closeToast;    

    /*--------------------------------------------------------------------------
    Implementations
    --------------------------------------------------------------------------*/
    function viewRec($event, rec) {
        console.log("View:", rec);
        $state.go('app.rec', { id: rec.recommendation_id });
    }

    function closeToast() {
        $scope.toastMessage = null;
        $scope.toastClass = null;
    }
    
}

export default {
    name: 'RecsCtrl',
    fn: RecsCtrl
};
