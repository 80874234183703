function moduleLoader(mod, type, map, recurse) {
    Object.keys(map).forEach((key) => {
        let item = map[key];

        if (!item) { return; }

        if (!recurse) {
            if (!item.default) { return; }

            if (item.default.fn && typeof item.default.fn === 'function') {
                mod[type](item.default.name, item.default.fn);
            }
        } else {
            if (item.fn && typeof item.fn === 'function') {
                mod[type](item.name, item.fn);
            } else {
                moduleLoader(mod, type, item, recurse);
            }
        }
    });
}

export default moduleLoader;
