// @ngInject
function HeaderBarDirective(Auth, $state, $http, Session,$window ) {
    function Link(scope) {
        scope.showMenu = false;
        var captions = Session.getJSON("captions");
        var user = Session.getJSON("user")
        scope.userName = user["first_name"] + " " + user["last_name"]
        scope.navItems = [
            {name: captions.home_menu, sref: 'app.home'}
        ];

        if ( user.claims.indexOf("view_recs") != -1){
            scope.navItems.push({name: captions.recommendations_menu, sref: 'app.recs'})
        }
        if ( user.claims.indexOf("view_survey") != -1){
             scope.navItems.push({name: captions.visits_menu, sref: 'app.visits'})
        }
        scope.navItems.push({name: captions.resources_menu, sref:'app.resources'});
        scope.navItems.push({name: captions.my_account_menu, sref: 'app.account'});

        // Functions
        // TODO: Implement auth service
        //scope.logout = Auth.logout;
        scope.logout = logout;
        scope.toggleMenu = toggleMenu;
        scope.contains = contains;

        function toggleMenu() {
            scope.showMenu = !scope.showMenu;
        }

        function contains(route) {
            return ($state.current.name.indexOf(route) > -1 || route.indexOf($state.current.name) > -1);
        }

        function logout(){
            Auth.reset();
            //Session.remove("auth");
            $window.localStorage.removeItem("auth");
            Session.remove("user");
            $state.go('login');
            delete $http.defaults.headers.common.Authorization;
        }   
    }

    return {
        restrict: 'E',
        templateUrl: 'directives/header/header.html',
        scope: {
            title: '@',
            message: '@clickMessage'
        },
        link: Link
    };
}

export default {
    name: 'headerBar',
    fn: HeaderBarDirective
};
