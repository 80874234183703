function BrandNavDirective() {
    function Link(scope, element) {
        element.on('click', () => {
            window.alert('Element clicked asdf ' + scope.message);
        })
    }

    return {
        restrict: 'E',
        templateUrl: 'directives/brandnav/brandnav.html',
        scope: {
            title: '@',
            message: '@clickMessage'
        },
        link: Link
    };
}

export default {
    name: 'brandNav',
    fn: BrandNavDirective
};
