// @ngInject
function AssetListCtrl($scope, $http, $window, $state, API, Session, Auth) {
    var captions = Session.getJSON("captions")
    $scope.AssetsTitle = captions["digital_assets"];
    var user = Session.getJSON("user");
    $scope.assets = {};
    $scope.url = API.url();

    API.get("/assets")
        .then((response) => {
            $scope.assets = response.data;
        })

    $scope.getAsset = getAsset;
    $scope.logUrlAccess = logUrlAccess

    function getAsset(assetId) {
        Auth.getResource(`/assets/${assetId}/file?`, user.user_id)
            .then(null, () => {
                console.log("Failure");
            });
    }

    function logUrlAccess(assetId, url){
        Auth.logUrl(`/assets/${assetId}/url?`, user.user_id, url)
            .then(null,() =>{
                console.log("Failure");
            } );
    }    
}

export default {
    name: 'AssetListCtrl',
    fn: AssetListCtrl
}