import angular from 'angular';
import moduleLoader from '../utils/loader';

const bulk = require('bulk-require');
const servicesModule = angular.module('app.services', []);
const services = bulk(__dirname, ['./**/!(*index|*.spec).js']);

moduleLoader(servicesModule, 'factory', services);

export default servicesModule;
