// ModelService: a utility service to support creating, valdating and sanitizing data models
// @ngInject
function ModelService() {
    // Schemas
    const ACCOUNT = {
        insuredName: "",
        type: "",
        address: "",
        address2: "",
        city: "",
        county: "",
        province: null,
        country: null,
        postalCode: "",
        phone: "",
        phone2: "",
        fax: "",
        geocode: "",
        parentAccountId: null,
        primaryContactId: null,
        serviceKey: "",
        active: true
    };

    // returns a copy of model
    function create(type) {
        switch(type.toLowerCase()) {
            case 'account': return Object.assign({}, ACCOUNT); break;
            default: break;
        }
    }

    // sanitizes the address components to only return the names
    function sanitizeAddressDto(model) {
        if (model.country && model.country.name) {
            model.country = model.country.name;
        }

        if (model.province && model.province.name) {
            model.province = model.province.name;
        }
    }

    /*
        @description: checks if the navigation target id is valid
        @params: id - string | int
        @return: valid - boolean
    */
    function validId(id) {
        return parseInt(id, 10) > 0;
    }

    return {
        sanitizeAddressDto: sanitizeAddressDto,
        validId: validId,
        create: create
    }
}

export default {
    fn: ModelService,
    name: 'Model'
}
