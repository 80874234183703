// @ngInject
function DBService(Session) {
    let _db = null;

    // check if we have support for index

    return {

    }
}

export default {
    name: 'DB',
    fn: DBService
};
