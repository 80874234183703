// @ngInject
function CommunicationsCtrl($scope, $http, API, Session, $timeout, $state, moment, Auth) {
    var captions = Session.getJSON('captions');
    var visitId = $state.params.id;
    var user = Session.getJSON("user");

    $scope.communicationTitle = captions["visits_communications"]

    $scope.communications = [];

    API.get(`/visits/${visitId}/letters`)
        .then((response) => {
            $scope.communications = response.data;
        });


    /*--------------------------------------------------------------------------
    Definitions
    --------------------------------------------------------------------------*/

    $scope.parseDate = parseDate;
    $scope.downloadAttachment = downloadAttachment;
    $scope.goBack = goBack;

    /*--------------------------------------------------------------------------
    Implementations
    --------------------------------------------------------------------------*/
    function parseDate(dateString) {
        // POLICY: prevent displaying 0
        if (!dateString || dateString == '0' || dateString == '') { return '-'; }
        console.log("date", dateString)
        return moment(dateString.substr(0, 8), "YYYYMMDD").format("YYYY-MMM-DD");
    }

    function downloadAttachment(attachmentname) {
        Auth.getResource(`/visits/${visitId}/letters/?attachmentName=${attachmentname}`, user.user_id)
            .then(null, () => {
                console.log("Failure");
            });
    }    

    function goBack(){
        $state.go('app.visit', { id: visitId });
    }
    

}

export default {
    fn: CommunicationsCtrl,
    name: 'CommunicationsCtrl'
}
