import angular from 'angular';

import '../../ada/ada';

// Configurations
import onConfig  from './on_config';
import onRun     from './on_run';

// Vendors
import 'ng-file-upload';
import 'angular-ui-router';
import 'angular-ui-bootstrap';
import 'ng-sortable';
import 'angular-messages';
import 'angular-animate';
import 'angular-ui-tinymce';
import 'tinymce';
import 'tinymce/themes/modern/theme'
import 'angular-sanitize';
import 'angular-xml-cjs2';
import 'angular-bootstrap-lightbox';
import 'angular-moment';
import 'angular-chart.js';
import 'angular-google-analytics';

// Application
import './templates';
import './filters';
import './controllers';
import './services';
import './directives';

// create and bootstrap application
const requires = [

    // Angular
    'ngMessages',
    'ngAnimate',
    'ngSanitize',

    // 3rd Party
    'ui.router',
    'ui.tinymce',
    'ui.bootstrap',
    'xml',
    'bootstrapLightbox',
    'angularMoment',
    'as.sortable',
    'ngFileUpload',
    'chart.js',

    // ADA
    'ada.services',
    'ada.directives',
    'ada.constants',

    // App
    'templates',
    'app.filters',
    'app.controllers',
    'app.services',
    'app.directives',

    //Google Analytics
    'angular-google-analytics'
];

angular
    .module('app', requires)
    .config(onConfig)
    .run(onRun);

angular.bootstrap(document, ['app'], {
    strictDi: true
});
