// @ngInject
function FooterBarDirective(Session, $state, $uibModal) {
    function Link(scope) {
        var modal = null;
        var captions = Session.getJSON("captions");
        scope.year = (new Date()).getFullYear();
        scope.legalInfo = captions["legal_body"];
        scope.openLegalModal = () => {
                    modal = $uibModal.open({
                    //animation: false,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'controllers/legal/legalModal.html',
                    size:'lg',
                    scope: scope,
                    windowClass: 'legal-modal'
                });
        }

        scope.closeLegalModal = () => {
            modal.close();
        }        
    }

    return {
        restrict: 'E',
        templateUrl: 'directives/footer/footer.html',
        scope: false,
        link: Link
    };
}

export default {
    name: 'footerBar',
    fn: FooterBarDirective
};
