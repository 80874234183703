// @ngInject
function LegalCtrl($scope, $rootScope, $http, Session, $state, $log, API) {
    var logger = $log.setContext("LegalCtrl");
    var captions = Session.getJSON("captions");
    $scope.legalInfo = 
    { 
        'key': 'legal_body',
        'value' :captions["legal_body"]    
    }

    $scope.legalHelpBody = `
        <p>Here you can configure the legal information displayed on the Terms and conditons pop-up</p>
    `;    

    API.get('/captions')
        .then((response) => {
            for ( var c = 0; c < response.data.length; c++){
                if (response.data[c].key == "legal_body"){
                    $scope.legalInfo.value = response.data[c].value; 
                }
            }           
        });

    $scope.saveLegal = saveLegal;
    $scope.closeToast = closeToast;



    function saveLegal() {
        API.put("/admin/captions/legal_body", $scope.legalInfo )
            .then((response) => {
                $scope.toastClass = "alert-success";
                $scope.toastMessage = "Legal info successfully Updated";  
                $scope.isSaving = false;  
            })
            .catch((errorResponse) => {
                $scope.toastClass = "alert-danger";
                $scope.toastMessage = errorResponse.data.message;
                $scope.isSaving = false;
            });
    }

    function closeToast() {
        $scope.toastMessage = null;
        $scope.toastClass = null;
    }        
}

export default {
    name: 'LegalCtrl',
    fn: LegalCtrl
};
