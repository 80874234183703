/*
    CaptionService just returns any mappings for predefined captions.
*/

// @ngInject
function CaptionService($http, $log, API, $rootScope, $q) {
    var _captions = {};
    var _languages = {};
    const _serviceName = '[ada.CaptionService]';

    var language = 1; // default language is english

    // actions
    const LANGUAGE_CHANGED = 'language_changed';

    function init() {
        return $q((resolve) => {
            $log.debug(`${_serviceName}: init() - getting languages`);

            // $http.get('samples/languages.json')
            API.get('/lists/languages')
            .then((response) => {
                _languages = {};

                for (var lang of response.data) {
                    _languages[lang.id] = lang;
                }

                $log.debug(`${_serviceName}: init() - _languages:`, _languages);
                $log.debug(`${_serviceName}: init() - getting captions`);

                // $http.get('samples/systemcaptions.json')
                API.get('/lists/systemcaptions')
                .then((response) => {
                    for (var cap of response.data) {
                        let area = cap.area.toLowerCase();

                        if (!_captions[cap.languageId]) {
                            _captions[cap.languageId] = Array();
                        }

                        if (!_captions[cap.languageId][area]){
                            _captions[cap.languageId][area] = Array();
                        }

                        _captions[cap.languageId][area][cap.code] = cap.caption;
                    }

                    $log.debug(`${_serviceName}: _captions`, _captions);
                },
                (response) => {
                    $log.error(`${_serviceName}: failed to get captions: `, response.data)
                })
                .finally(() => {
                    notify();
                    resolve();
                });
            },
            (response) => {
                $log.error(`${_serviceName}: failed to get languages: `, response.data)
                notify();
                resolve();
            });
        })
    }

    function get(caption,area,_language) {
        _language = _language || language;

        if (!(_captions[_language] &&
            _captions[_language][area] &&
            _captions[_language][area][caption])) {
                return caption;
            }

        return _captions[_language][area][caption];
    }

    function set(captions) {
        _captions = captions;
    }

    function setLanguage(lang){
        console.debug("[CaptionService.setLanguage] lang: ", lang);
        language = lang;

        notify();
    }

    function getCurrentLanguage(){
        return language;
    }

    function getCurrentLanguageName() {
        return _languages[language] && _languages[language].name || language;
    }

    function getCurrentLanguageIso() {
        return _languages[language] && _languages[language].isoCode || language;
    }

    function getAllLanguages() {
        return _languages;
    }

    // subscribes a listener and callback
    function subscribe(scope, callback) {
        var listener = $rootScope.$on(LANGUAGE_CHANGED, callback);

        // destroy the listener at end of controller's lifecycle
        scope.$on('$destroy', listener);
    }

    // subscribes to notifications and appends based on an array
    function subscribeCaptions(scope, ctrlCaptions, area) {
        // ignore empty arrays
        if (!ctrlCaptions || ctrlCaptions.length < 1) { return; }

        scope._updateCaptions = () => {
            for (var i in ctrlCaptions) {
                if (typeof ctrlCaptions[i] === 'object') {
                    scope[ctrlCaptions[i].target] = get(ctrlCaptions[i].source, area);
                } else if (typeof ctrlCaptions[i] === 'string') {
                    scope["caption_" + ctrlCaptions[i]] = get(ctrlCaptions[i], area);
                }
            }
        }

        // CASE: prevent flashing of captions for slow connections
        scope._updateCaptions();

        subscribe(scope, scope._updateCaptions);
    }

    function notify() {
        $log.debug("[CaptionService] notifying listeners");
        $rootScope.$emit(LANGUAGE_CHANGED);
    }

    return {
        init: init,
        get: get,
        set: set,

        setLanguage: setLanguage,
        getCurrentLanguage: getCurrentLanguage,
        getAllLanguages: getAllLanguages,
        getCurrentLanguageName: getCurrentLanguageName,
        getCurrentLanguageIso: getCurrentLanguageIso,

        // notifications
        subscribe: subscribe,
        subscribeCaptions: subscribeCaptions,
        notify: notify
    }
}

export default {
    name: 'CaptionService',
    fn: CaptionService
}
