import Logger from '../utils/logger';

/**
 *  AuthService: deals with authorization and authentication of user.
 *  To handle user information, see UserService
 *  @module AuthService
 */
// @ngInject
function AuthService(Session, $http, API, $state, $q, $log, $window) {
    // caches
    var auth = null;
    var auth_key = 'auth';
    var auth_type = '';

    var logger = Logger('ada.AuthService', $log);

    const AUTH_ERRORS = {
        INVALID_AUTH_TYPE: 'Auth type is invalid'
    };

    const AUTH_TYPES = {
        'basic': 'BASIC',
        'oauth': 'OAUTH',
        'bearer': 'BEARER'
    }

    /**
    * Initializes the service by checking if there is a user with a valid session
    * @function init
    * @return {Promise} { token_type, access_token } || null
    */
    function init(type) {
        return $q((resolve) => {
            auth_type = type;
            logger.debug("Auth.init()");
            auth = Session.get(auth_key);
            if (auth) { updateHTTPHeader(type); }
            resolve(auth);
        })
    }

    /**
    * Initializes the service by checking if there is a user with a valid session
    * @function init
    * @return {Promise} { token_type, access_token } || null
    */
    function updateHTTPHeader(type) {
        logger.debug("updateHTTPHeader()");

        switch(type) {
            case 'bearer':
                $http.defaults.headers.common.Authorization = `Bearer ${auth}`;
                break;            
            case 'jwt':
                $http.defaults.headers.common.Authorization = `${auth.token_type} ${auth.access_token}`;
                break;
            case 'basic':
                $http.defaults.headers.common.Authorization = `Basic ${auth}`;
                break;
        }
    }

    /**
    * Checks if current user has a valid session
    * @function hasValidSession
    * @return {boolean}
    */
    function hasValidSession() {
        return !!auth;
    }

    // retrieves a token or returns null
    function getToken() {
        return auth;
    }

    // removes auth
    function reset() {
        Session.remove("auth");
        $window.localStorage.removeItem("auth");;
        auth = null;
        delete $http.defaults.headers.common.Authorization;
    }

    /**Gets the resource using the access_token
     * @function getResource
     * @param {path} the path of the requested file to have the access token for
     */
    function getResource(resourcePath,userId){
        return $q((resolve,reject) => {
            API.post('/access_token', {
                "path":resourcePath,
                "user_id":userId
            })
                .then((tokenResponse) =>{
                    $window.open(API.url() + resourcePath + '&accessToken=' + tokenResponse.data.token, "_blank");
                    resolve();
                }, reject);
        })
    }
    /**Logs the URL click of the given asset
     * @function getResource
     * @param {path} the path of the requested file to have the access token for
     */
    function logUrl(resourcePath,userId,url){
        return $q((resolve,reject) => {
            API.post('/access_token', {
                "path":resourcePath,
                "user_id":userId
            })
                .then((tokenResponse) =>{
                    API.post(resourcePath + '&accessToken=' + tokenResponse.data.token)
                        .then((logResponse) => {
                            $window.open(url, "_blank");
                        });                    
                    resolve();
                }, reject);
        })
    }    

    /**
    * Attempts to authorize user against API, this currently supports SAWS
    * @function login
    * @param {credentials} The user's login credentials { username, password }
    * @param {type} The of auth to use: oauth | basic
    */
    function login(credentials, type) {
        return $q((resolve, reject) => {
            if (!type ||
                typeof type !== 'string' ||
                (typeof type === 'string' && !AUTH_TYPES[type])) {
                logger.error("INVALID_AUTH_TYPE");
                reject(AUTH_ERRORS["INVALID_AUTH_TYPE"]);
                return;
            }

            var url = '';
            var options = {};

            switch(type.toLowerCase()) {
                case 'bearer':
                    API.post("/login", credentials)
                        .then(
                        (response) => {
                            auth = response.data.token;
                            Session.set("auth", response.data.token);
                            Session.setJSON("user", response.data);
                            $window.localStorage.setItem("auth",response.data.token)
                            $http.defaults.headers.common.Authorization = "Bearer " + response.data.token;

                            resolve();
                        },
                        (response) => {
                            reject(response)
                        }
                    );
                
                    break;
            }
        })
    }

    function logout() {
        return $q((resolve) => {
            reset();

            delete $http.defaults.headers.common.Authorization;
            resolve();
        })
    }

    return {
        init: init,
        reset: reset,
        getToken: getToken,
        getResource: getResource,
        logUrl: logUrl,
        login: login,
        logout: logout,
        hasValidSession: hasValidSession
    };
}

export default {
    name: 'Auth',
    fn: AuthService
}
