// TODO: add IDB support

/*
    InspectionService: adds ability to cache locally and then update the user's inspection.
*/

// @ngInject
function InspectionService(Session, API, x2js) {
    let _inspection = null;
    const _inspectionKey = 'inspection';

    function get() {
        if (_inspection) { return _inspection; }
        else {
            set(Session.getJSON(_inspectionKey));
            return _inspection;
        }
    }

    function set(inspection) {
        if (!inspection) { return; }

        _inspection = inspection;
        Session.setJSON(_inspectionKey, _inspection);
    }

    function clear() {
        _inspection = null;
        Session.remove(_inspectionKey);
    }

    function update() {
        _inspection = Session.getJSON(_inspectionKey);

        if (!_inspection) {
            // console.log("No inspection")
            // API.get('/inspections/1000001')
            // .then(
            //     (response) => {
            //         console.info("Inspection updated...");
            //         _inspection = x2js.xml_str2json(response.data);
            //
            //         if (_inspection) {
            //             Session.setJSON(_inspectionKey, _inspection);
            //         } else {
            //             Session.remove(_inspectionKey);
            //         }
            //     }
            // )
        } else {
            console.info("We have a cached inspection:");
        }
    }

    function getRecs() {
        let recs = {
            open: [],
            completed: []
        };

        if (_inspection.Inspection &&
            _inspection.Inspection.InspectionItems &&
            _inspection.Inspection.InspectionItems.InspectionItem) {
            for (var i = 0; i < _inspection.Inspection.InspectionItems.InspectionItem.length; i++) {
                var inspection = _inspection.Inspection.InspectionItems.InspectionItem[i];
                if (inspection.Form) {
                    var form = inspection.Form;

                    if (form.Sections.FormSection instanceof Array) {
                        for (var s = 0; s < form.Sections.FormSection.length; s++) {
                            var section = form.Sections.FormSection[s]
                            _getRecs(section, recs);
                        }
                    } else {
                        _getRecs(form.Sections.FormSection, recs);
                    }
                }
            }
        }

        return recs;
    }

    function _getRecs(section, recs) {
        if (section.Recommendations && section.Recommendations.Recommendation) {
            if (section.Recommendations.Recommendation instanceof Array) {
                for (var r = 0; r < section.Recommendations.Recommendation.length; r++) {
                    var rec = section.Recommendations.Recommendation[r];
                    _insertRec(rec, recs);
                }
            } else {
                _insertRec(section.Recommendations.Recommendation, recs);
            }
        }
    }

    function _insertRec(rec, recs) {
        // process the rec's title
        rec._title = rec.ExtensionFields.ExtensionField[0].Value.__text;

        // process severity
        for (var s = 0; s < rec.Severity.Option.length; s++) {
            var severity = rec.Severity.Option[s];
            if (severity._selected === "true") {
                rec._severity = severity.Label;
                break;
            }
        }

        // process
        rec._dueDate = (rec.CompleteBy === "0") ? "-" : rec.CompleteBy;

        if (_recIsOpen(rec)) {
            recs.open.push(rec);
        } else {
            recs.completed.push(rec);
        }
    }

    function _recIsOpen(rec) {
        if (rec.Status) {
            var open = false;

            for (var s = 0; s < rec.Status.Option.length; s++) {
                var status = rec.Status.Option[s];
                if (status._selected === "true") {
                    rec._status = status;

                    open = status.Label === "Open";
                }
            }

            return open;
        }
    }

    // Gets the assigned label in an <options>
    function _getLabel(status) {
        if (status && status.Option) {
            for (var i = 0; i < status.Option.length; i++) {
                if (status.Option[i]._selected === "true") {
                    return status.Option[i].Label;
                }
            }
        }
    }

    return {
        get: get,
        set: set,
        clear: clear,
        update: update,

        recs: getRecs
    }
}

export default {
    name: 'Inspection',
    fn: InspectionService
};
